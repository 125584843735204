@font-face {
  font-family: "SuperCool";
  src: local("SuperCool"), url("./assets/SuperCool.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SuperWobbly";
  src: local("SuperWobbly"), url("./assets/SuperWobbly.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  margin: 0;
  padding: 0;
}

html {
  background-color: black;
}

:root {
  --margin: 30px;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: auto;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: black;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

a {
  text-decoration: none;
}

#name {
  text-align: center;
  width: 100%;
  top: 0px;
  background-image: linear-gradient(45deg, #d7b985, #e76c20);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

#contacts {
  /*   background-image: linear-gradient(-45deg, #d7b985, #e76c20); */

  /* background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; */
}

#cloud-img {
  max-width: 80vw;
  max-height: 30vh;
  margin: auto;
  user-select: none;
}

.container {
  background-color: #0a0a0a;
  border-radius: 20px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  max-width: 300px;
  gap: 40px;
}

.container-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.container-graph {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.container-metrics {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.tag {
  border-radius: 5px;
  padding: 5px 10px;
}

.tag.latest {
  background-color: #59ab4c;
  color: black;
}

.tag.upcoming {
  background-color: #282828;
  color: #e9e2d2;
}

.metric {
  flex: 1;
}

.metrics-duration {
  position: absolute;
  top: 48px;
}

h1,
h2,
h3,
h4 {
  color: #e9e2d2;
  line-height: 1.2;
}

h1 {
  font-family: "SuperWobbly";
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 2px;
}

h2 {
  font-weight: 500;
  font-size: 20px;
}

h3 {
  font-weight: 400;
  font-size: 18px;
}

h4 {
  font-weight: 200;
  font-size: 14px;
}

p {
  font-size: 14px;
}
